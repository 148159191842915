<template>
  <div :key="componentKey" class="youtube-player">
    <button
      class="poster active"
      :title="useMicroCopy('youtube.playbutton.label')"
      @click="hideCustomCover($event)"
    >
      <SvgIcon name="func_play" />
      <nuxt-img
        v-if="blok.poster.filename"
        :src="blok.poster.filename"
        alt=""
        :sizes="sizes"
        class="asset-image"
      />
      <img
        v-if="!blok.poster.filename && youtube_video_id"
        :src="`https://img.youtube.com/vi/${youtube_video_id}/maxresdefault.jpg`"
        loading="lazy"
        alt=""
        class="asset-image"
      />
    </button>
    <component
      :is="'script'"
      id="youtube-iframe-js-api-script"
      src="https://www.youtube.com/iframe_api"
    />
    <ClientOnly>
      <YouTube
        v-if="!useDidomi || cookiesAccepted"
        ref="youtube"
        :src="blok.youtube_link"
        :vars="youtubeParams"
        @state-change="(state) => tracking(state)"
      />
      <div
        v-if="useDidomi && !cookiesAccepted"
        id="youtube-overlay"
        class="video-consent-overlay"
      >
        <div class="video-consent-overlay-text">
          {{ useMicroCopy("youtube.consent.text") }} <br />
          <a
            :href="useMicroCopy('youtube.consent.learn.more.link')"
            target="_blank"
            class="learn-more"
            tabindex="-1"
          >
            {{ useMicroCopy("youtube.consent.learn.more.cta") }}
          </a>
        </div>
        <SimpleButton
          :xl="false"
          class="accept-yt-cookies"
          @click="() => setPositiveConsentStatusForVendor('c:youtube')"
          tabindex="-1"
        >
          {{ useMicroCopy("youtube.consent.cta.label") }}
        </SimpleButton>
      </div>
    </ClientOnly>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import YouTube from "vue3-youtube";

import { useTrackingStore } from "@/stores/tracking";
import { useSnakeCaseFilter } from "@/composables/SnakecaseFilter";

const config = useRuntimeConfig();
const trackingStore = useTrackingStore();
const props = defineProps({
  blok: {
    type: Object,
    required: true,
  },
  stopVideo: {
    type: Boolean,
    required: false,
    default: false,
  },
  playDisabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  sizeHint: {
    type: Number,
    required: false,
    default: 100,
  },
});

const { blok, playDisabled } = toRefs(props);
const componentKey = ref(0);
const youtube = ref(null);
const cookiesAccepted = ref(false);
const youtubeParams = reactive({
  enablejsapi: 1,
  rel: 0,
  showinfo: 0,
  playsinline: 1,
  origin: config.public["baseURL"],
  autoplay: 0,
});

const youtube_video_id = blok.value.youtube_link?.match(
  /youtube\.com.*(\?v=|\/embed\/)(.{11})/
)
  ? blok.value.youtube_link
      ?.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/)
      ?.pop()
  : blok.value.youtube_link.match(/youtu.be\/(.{11})/)
  ? blok.value.youtube_link.match(/youtu.be\/(.{11})/)?.pop()
  : null;

const hideCustomCover = (event) => {
  if (!playDisabled.value) {
    event.target.offsetParent.classList.remove("active");

    if (cookiesAccepted.value === true) {
      playYoutube();
    }
  }
};

const playYoutube = () => {
  if (youtube?.value) {
    youtube.value.playVideo();
  }
};

const stopYoutube = () => {
  if (youtube?.value) {
    youtube.value.stopVideo();
  }
};

const forceRerender = () => {
  componentKey.value += 1;
};

const useDidomi =
  config.public["didomiApiKey"] || config.public["didomiApiKey"] != "";

onMounted(() => {
  setTimeout(() => {
    forceRerender();
  }, 1000);

  if (useDidomi) {
    initDidomi();
  }
});

const tracking = (state) => {
  if (youtube?.value) {
    if (state.data === 1) {
      trackingStore.sendTrackingData({
        event: "click.navigation",
        click: useSnakeCaseFilter(youtube.value?.player?.videoTitle),
        click_chapter2: "video",
      });
    }
  }
};

function initDidomi() {
  window.didomiOnReady = window.didomiOnReady || [];
  window.didomiOnReady.push(function (Didomi) {
    Didomi.getObservableOnUserConsentStatusForVendor("c:youtube").subscribe(
      function (consentStatus) {
        if (consentStatus === true) {
          cookiesAccepted.value = consentStatus;
        }
      }
    );
  });
}

function setPositiveConsentStatusForVendor(vendorId) {
  var purposes = Didomi.getVendorById(vendorId)?.purposeIds;
  var transaction = Didomi.openTransaction();

  transaction.enableVendor(vendorId);
  transaction.enablePurposes(...purposes);
  transaction.commit();

  setTimeout(() => {
    playYoutube();
  }, 1000);
}

watch(
  () => props.stopVideo,
  () => {
    if (props.stopVideo) {
      stopYoutube();
    }
  }
);

const sizes = computed(() => {
  return `phone:100vw desktop:${props.sizeHint}vw`;
});
</script>

<style lang="scss" scoped>
.youtube-player {
  aspect-ratio: 16/9;
  position: relative;
  .facility-pictures & {
    aspect-ratio: inherit;
  }
  width: 100% !important;
  border-radius: $radius-l;
  overflow: hidden;

  :deep(iframe),
  :deep(> div) {
    width: 100% !important;
    height: 100% !important;
  }

  .video-consent-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include pair-2;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    box-sizing: border-box;
    padding: 0 5px;

    @include for-tablet-portrait-up {
      justify-content: center;
    }
  }
  .video-consent-overlay-text {
    text-align: center;
    font-size: 0.875rem;
    font-weight: 500;
    max-width: 37.5rem;
    line-height: 150%;
    @include for-tablet-portrait-up {
      font-size: 1rem;
    }
  }

  .learn-more {
    text-decoration: underline;
  }

  .accept-yt-cookies {
    min-height: 2rem;
    margin-bottom: 1.25rem;
    @include pair-2;

    @include for-tablet-portrait-up {
      margin-top: 1.5rem;
      $height: 3.5rem;
      min-height: $height;
      border-radius: calc($height / 2);
      padding: 0 2rem;
      font-size: 1.125rem;
    }
  }
}

.poster {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  display: none;

  &.active {
    display: block;
  }

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 3.5rem;
    height: 3.5rem;
    pointer-events: none;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
